import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  api_url: string = 'https://api.boettinger.media/bvmg/contact';
  //api_url:string = 'http://0.0.0.0:8081/api/contact'

  constructor(private http: HttpClient) {}

  submit_contact(name, email, phone, message) {
    this.http
      .post<any>(this.api_url, {
        name: name,
        email: email,
        phone: phone,
        message: message,
      })
      .subscribe((data) => {
        console.log('data', data);
      });
  }
}
