<div class="opacity-wrapper"></div>
<div class="title-wrapper">
  <div class="title">{{ "MENU_LEISTUNGEN" | translate }}</div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "COURTAGEHINWEIS_TITLE" | translate }}</div>
  <div class="content-text">{{ "COURTAGEHINWEIS" | translate }}</div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "LEISTUNG_WIEWIR" | translate }}</div>
  <div class="content-text">
    <p>{{ "WIE_WIR_ARBEITEN" | translate }}</p>

    <div class="content-wrapper-flex">
      <div class="flex">
        <div class="content-subtitle">
          {{ "GENUTZER_SOFTWARE" | translate }}
        </div>
        <div class="partner-wrapper">
          <img class="partner-item-img" src="../../../assets/software-1.png" />
          <img class="partner-item-img" src="../../../assets/software-2.gif" />
        </div>
      </div>

      <div class="flex">
        <div class="content-subtitle">
          {{ "PARTNER_VERBAENDE" | translate }}
        </div>
        <div class="partner-wrapper">
          <img class="partner-item-img" src="../../../assets/partner-2.jpg" />
          <img class="partner-item-img" src="../../../assets/partner-1.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "LEISTUNG_THEMEN" | translate }}</div>
  <div class="content-text">{{ "UNSERE_THEMENBEREICHE" | translate }}</div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "LEISTUNG_ERWARTEN" | translate }}</div>
  <div class="content-text">
    {{ "WAS_SIE_BEI_UNS_ERWARTEN_KÖNNEN" | translate }}
  </div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "LEISTUNG_VERSICHERER" | translate }}</div>
  <div class="content-text">{{ "VERSICHERER" | translate }}</div>
</div>

<div class="content-wrapper" style="margin-bottom: 100px">
  <div class="content-title">{{ "ERREICHEN_SIE_UNS" | translate }}</div>
  <div class="content-text">
    <p>{{ "SO_ERREICHEN_SIE_UNS" | translate }}</p>
    <p>
      <br />

      <span class="p-bold">{{ "TELEFON" | translate }}: </span>+49 (0) 711 /
      974611 - 0<br />
      <span class="p-bold">{{ "TELEFAX" | translate }}: </span>+49 (0) 711 /
      974611 - 66<br />
      <span class="p-bold">Email: </span>info@boettinger-gmbh.de<br />
    </p>
  </div>
</div>
