<div class="opacity-wrapper"></div>
<div class="page-wrapper top-wrapper">
  <div class="content-wrapper">
    <div class="title-wrapper">
      <div class="subtitle subtitle-flowtext">
        <!--<div class="celebration-subtitle">
          <img
            class="celeb-img"
            src="../../../assets/30years.png"
            alt="30 Jahre"
          />
          <div class="celeb-text">{{ "TITLE_CELEB" | translate }}</div>
        </div>-->
        <span class="subtitle-item">{{ "TITLE_BESTENS" | translate }}</span>
      </div>
      <div class="home-title">{{ "TITLE_BOET" | translate }}</div>
      <div class="home-title">{{ "TITLE_VER" | translate }}</div>
      <div class="home-title">{{ "TITLE_GMBH" | translate }}</div>
    </div>

    <div class="subtitle">
      <div class="subtitle-item customA" [routerLink]="['/kontakt']">
        {{ "TITLE_TERMIN" | translate }}
      </div>
      <div
        class="subtitle-item text-align-right customA"
        [routerLink]="['/schaden']"
      >
        {{ "TITLE_SCHADEN" | translate }}
      </div>
    </div>
  </div>

  <!-- Celebration -->
  <div class="new-celeb-wrapper">
    <div style="font-weight: bold; font-size: 25px">
      {{ "TITLE_CELEB" | translate }}
    </div>
    <div style="font-weight: 400">1994 - 2024</div>
  </div>
</div>
