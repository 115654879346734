<div class="opacity-wrapper"></div>
<div class="title-wrapper">
  <div class="title">{{ "MENU_UBERUNS" | translate }}</div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "COMPANY" | translate }}</div>
  <div class="content-text">{{ "UEBER_UNS" | translate }}</div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "US_GESCHICHTE" | translate }}</div>
  <div class="content-text">{{ "FIRMENGESCHICHTE" | translate }}</div>
</div>

<div class="content-wrapper" style="margin-bottom: 100px">
  <div class="content-subtitle">{{ "PARTNER_VERBAENDE" | translate }}</div>
  <div class="partner-wrapper">
    <img class="partner-item-img" src="../../../assets/partner-2.jpg" />
    <img class="partner-item-img" src="../../../assets/partner-1.jpg" />
  </div>
</div>
