import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  header_menu: string = 'home';
  resp_menu_open: boolean = false;

  constructor(public translate: TranslateService, private router: Router) {}

  ngOnInit(): void {
    let url_array = window.location.href.split('/');
    this.header_menu = url_array[url_array.length - 1];
  }

  changeLang(lang) {
    this.translate.use(lang);

    console.log(this.translate.currentLang);
  }

  toggleRespMenu(action: boolean) {
    if (this.resp_menu_open || !action) {
      this.resp_menu_open = false;
    } else if (!this.resp_menu_open || action) {
      this.resp_menu_open = true;
    }
  }
}
