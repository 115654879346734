import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
})
export class ImpressumComponent implements OnInit {
  fragment: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    //window.scrollTo(0, 0);
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
  }

  ngAfterViewChecked(): void {
    try {
      if (this.fragment) {
        document.querySelector('#' + this.fragment).scrollIntoView();
      } else {
        window.scrollTo(0, 0);
      }
    } catch (e) {}
  }
}
