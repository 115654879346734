<div class="opacity-wrapper"></div>
<div class="title-wrapper">
  <div class="title">{{ "MENU_SCHADEN" | translate }}</div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "SCHADEN_BESCHREIB" | translate }}</div>
  <div class="schaden-success" *ngIf="success">
    Vielen Dank für Ihre Nachricht! <br />
    Wir melden uns unverzüglich bei Ihnen.
  </div>

  <div class="schaden-form-wrapper" *ngIf="!success">
    <div class="schaden-form-top">
      <input
        #name
        class="schaden-form-input"
        placeholder="{{ 'INPUT_NAME' | translate }}"
        type="text"
        [class.error_form]="error_name === true"
        required
      />
      <input
        #email
        class="schaden-form-input"
        placeholder="{{ 'INPUT_MAIL' | translate }}"
        type="email"
        required
        [class.error_form]="error_mail"
      />
      <input
        #telefon
        class="schaden-form-input"
        placeholder="{{ 'INPUT_TEL' | translate }}"
        type="tel"
      />
    </div>
    <div class="schaden-form-text">
      <textarea
        #message
        [class.error_form]="error_message"
        class="schaden-form-textarea"
        placeholder="{{ 'INPUT_MSG' | translate }}"
        required
      ></textarea>
    </div>
    <div class="submit-button" (click)="send_msg()">
      {{ "INPUT_SUBMIT" | translate }}
    </div>
    <div style="clear: both"></div>
  </div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "SCHADEN_DATEN" | translate }}</div>
  <div class="content-text">{{ "DIE_DATEN_BENÖTIGEN_WIR" | translate }}</div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "ERREICHEN_SIE_UNS" | translate }}</div>
  <div class="content-text">
    <p>{{ "SO_ERREICHEN_SIE_UNS" | translate }}</p>
    <p>
      <br />

      <span class="p-bold">{{ "TELEFON" | translate }}: </span>+49 (0) 711 /
      974611 - 0<br />
      <span class="p-bold">{{ "TELEFAX" | translate }}: </span>+49 (0) 711 /
      974611 - 66<br />
      <span class="p-bold">Email: </span>info@boettinger-gmbh.de<br />
    </p>
  </div>
</div>

<div class="content-wrapper">
  <div class="content-title">
    {{ "WEITERFÜHRENDE_LINKS_TITLE" | translate }}
  </div>
  <div class="content-text">
    <p class="p-bold">{{ "WEITERFÜHRENDE_LINKS" | translate }}</p>

    <div class="link-content-wrapper">
      <div class="link-wrapper">
        <p class="p-bold">Sprint</p>
        <p>Sanierung von Wasser- und Brandschäden</p>
        <p>
          <a
            href="https://www.sprint.de  
          "
            target="_blank"
            >www.sprint.de
          </a>
        </p>
      </div>

      <div class="link-wrapper">
        <p class="p-bold">Belfor</p>
        <p>Sanierung von Wasser- und Brandschäden</p>
        <p>
          <a
            href="https://www.belfor.com/de/de/regionalniederlassung-stuttgart  
          "
            target="_blank"
            >www.belfor.com.de/de
          </a>
        </p>
      </div>

      <div class="link-wrapper">
        <p class="p-bold">Polygon</p>
        <p>Spezialfirma zur sofortigen Hilfe bei Wasser- und Brandschäden</p>
        <p>
          <a href="https://www.polygongroup.de " target="_blank"
            >www.polygongroup.de
          </a>
        </p>
      </div>

      <div class="link-wrapper">
        <p class="p-bold">Rainbow</p>
        <p>Sanierung von Wasser- und Brandschäden</p>
        <p>
          <a
            href="https://www.rainbow-international.de
          "
            target="_blank"
            >www.rainbow-international.de
          </a>
        </p>
      </div>

      <div class="link-wrapper">
        <p class="p-bold">Carglass</p>
        <p>Glasreparatur bei Kfz-Schäden</p>
        <p>
          <a
            href="https://www.carglass.de 
          "
            target="_blank"
            >www.carglass.de
          </a>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="content-wrapper" style="margin-bottom: 100px">
  <div class="content-title">
    {{ "DATENSPEICHERUNGS_HINWEIS_TITLE" | translate }}
  </div>
  <div class="content-text">{{ "DATENSPEICHERUNGS_HINWEIS" | translate }}</div>
</div>
