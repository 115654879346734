<div class="header-wrapper">
  <div
    class="logo-wrapper"
    (click)="header_menu = 'home'; toggleRespMenu(false)"
  >
    <img
      class="logo-wrapper-img customA"
      src="../../../assets/logo.png"
      alt="Böttinger Versicherungsmakler Logo"
      [routerLink]="['/home']"
    />
  </div>

  <div class="menu-wrapper">
    <!--<div
      [class.menu-item-active]="header_menu == 'home'"
      (click)="header_menu = 'home'"
      class="menu-item"
      [routerLink]="['/home']"
    >
      Home
    </div>-->
    <div
      [class.menu-item-active]="header_menu == 'makler'"
      (click)="header_menu = 'makler'"
      class="menu-item"
      [routerLink]="['/makler']"
    >
      {{ "MENU_UBERUNS" | translate }}
    </div>
    <div
      [class.menu-item-active]="header_menu == 'leistungen'"
      (click)="header_menu = 'leistungen'"
      class="menu-item"
      [routerLink]="['/leistungen']"
    >
      {{ "MENU_LEISTUNGEN" | translate }}
    </div>
    <div
      [class.menu-item-active]="header_menu == 'schaden'"
      (click)="header_menu = 'schaden'"
      class="menu-item"
      [routerLink]="['/schaden']"
    >
      {{ "MENU_SCHADEN" | translate }}
    </div>
    <div
      [class.menu-item-active]="header_menu == 'kontakt'"
      (click)="header_menu = 'kontakt'"
      class="menu-item"
      [routerLink]="['/kontakt']"
    >
      {{ "MENU_KONTAKT" | translate }}
    </div>
    <div
      [class.menu-item-active]="header_menu == 'links'"
      (click)="header_menu = 'links'"
      class="menu-item"
      [routerLink]="['/links']"
    >
      {{ "MENU_LINKS" | translate }}
    </div>
  </div>

  <div class="trans-wrapper">
    <img
      class="language-flag"
      *ngIf="translate.currentLang === 'en'"
      (click)="changeLang('de')"
      src="../../../assets/de.png"
    />
    <img
      class="language-flag"
      *ngIf="translate.currentLang === 'de'"
      (click)="changeLang('en')"
      src="../../../assets/uk.png"
    />
  </div>

  <!-- Responsive Menu stuff -->
  <img
    class="menu-icon"
    alt="Menu steuern"
    src="../../../assets/menu.svg"
    (click)="toggleRespMenu(true)"
  />
</div>

<div class="resp-menu-wrapper" *ngIf="resp_menu_open">
  <div class="resp-menu-nav-wrapper">
    <div
      [class.menu-item-active]="header_menu == 'makler'"
      (click)="header_menu = 'makler'; toggleRespMenu(false)"
      class="menu-item-resp"
      [routerLink]="['/makler']"
    >
      {{ "MENU_UBERUNS" | translate }}
    </div>
    <div
      [class.menu-item-active]="header_menu == 'leistungen'"
      (click)="header_menu = 'leistungen'; toggleRespMenu(false)"
      class="menu-item-resp"
      [routerLink]="['/leistungen']"
    >
      {{ "MENU_LEISTUNGEN" | translate }}
    </div>
    <div
      [class.menu-item-active]="header_menu == 'schaden'"
      (click)="header_menu = 'schaden'; toggleRespMenu(false)"
      class="menu-item-resp"
      [routerLink]="['/schaden']"
    >
      {{ "MENU_SCHADEN" | translate }}
    </div>
    <div
      [class.menu-item-active]="header_menu == 'kontakt'"
      (click)="header_menu = 'kontakt'; toggleRespMenu(false)"
      class="menu-item-resp"
      [routerLink]="['/kontakt']"
    >
      {{ "MENU_KONTAKT" | translate }}
    </div>
    <div
      [class.menu-item-active]="header_menu == 'links'"
      (click)="header_menu = 'links'; toggleRespMenu(false)"
      class="menu-item-resp"
      [routerLink]="['/links']"
    >
      {{ "MENU_LINKS" | translate }}
    </div>
  </div>

  <div class="resp-menu-lang-wrapper">
    <img
      class="language-flag"
      *ngIf="translate.currentLang === 'en'"
      (click)="changeLang('de')"
      src="../../../assets/de.png"
    />
    <img
      class="language-flag"
      *ngIf="translate.currentLang === 'de'"
      (click)="changeLang('en')"
      src="../../../assets/uk.png"
    />
  </div>
</div>
