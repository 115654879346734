import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../shared/api.service';

@Component({
  selector: 'app-schaden',
  templateUrl: './schaden.component.html',
  styleUrls: ['./schaden.component.scss'],
})
export class SchadenComponent implements OnInit {
  @ViewChild('name') name: any;
  @ViewChild('email') email: any;
  @ViewChild('telefon') telefon: any;
  @ViewChild('message') message: any;

  success: boolean = false;
  error_name: boolean = false;
  error_message: boolean = false;
  error_mail: boolean = false;

  constructor(private API: ApiService) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  send_msg() {
    this.error_name = false;
    this.error_message = false;
    this.error_mail = false;

    if (
      this.name.nativeElement.value === undefined ||
      this.name.nativeElement.value === ''
    ) {
      this.error_name = true;
      return;
    }
    if (
      this.email.nativeElement.value === undefined ||
      this.email.nativeElement.value === ''
    ) {
      this.error_mail = true;
      return;
    }
    if (
      this.message.nativeElement.value === undefined ||
      this.message.nativeElement.value === ''
    ) {
      this.error_message = true;
      return;
    }

    this.API.submit_contact(
      this.name.nativeElement.value,
      this.email.nativeElement.value,
      this.telefon.nativeElement.value,
      this.message.nativeElement.value
    );
    this.success = true;
  }
}
