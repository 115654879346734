import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HttpClientJsonpModule,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { LeistungenComponent } from './pages/leistungen/leistungen.component';
import { SchadenComponent } from './pages/schaden/schaden.component';
import { MaklerComponent } from './pages/makler/makler.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LinksComponent } from './pages/links/links.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { NotfoundComponent } from './pages/notfound/notfound.component';
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LeistungenComponent,
    SchadenComponent,
    MaklerComponent,
    KontaktComponent,
    FooterComponent,
    LinksComponent,
    ImpressumComponent,
    DatenschutzComponent,
    NotfoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [{ provide: localeDe, useValue: 'de-DE' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
