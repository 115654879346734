<div class="opacity-wrapper"></div>
<div class="title-wrapper">
  <div class="title">{{ "MENU_LINKS" | translate }}</div>
</div>

<div class="content-wrapper" style="margin-bottom: 100px">
  <div class="content-title">
    {{ "WEITERFÜHRENDE_LINKS_TITLE" | translate }}
  </div>
  <div class="content-text">
    <p class="p-bold">
      {{ "WEITERFÜHRENDE_LINKS" | translate }}
    </p>
  </div>

  <div class="link-content-wrapper">
    <div class="link-wrapper">
      <p class="p-bold">Zu Schnell Gefahren</p>
      <p>Bußgeldrechner</p>
      <p>
        <a href="https://www.zu-schnell-gefahren.de" target="_blank"
          >www.zu-schnell-gefahren.de</a
        >
      </p>
    </div>

    <div class="link-wrapper">
      <p class="p-bold">Prozesskostenrechner</p>
      <p>Kosten bei einem Rechtsstreit</p>
      <p>
        <a href="https://www.prozesskostenrechner.de" target="_blank"
          >www.prozesskostenrechner.de</a
        >
      </p>
    </div>

    <div class="link-wrapper">
      <p class="p-bold">KFZ-Steuer</p>
      <p>Onlineberechnung der Kfz-Steuer</p>
      <p>
        <a href="https://www.kfz-steuer.de" target="_blank"
          >www.kfz-steuer.de</a
        >
      </p>
    </div>

    <div class="link-wrapper">
      <p class="p-bold">VDS</p>
      <p>
        Vertrauen und Sicherheit - hier finden Sie z.B. anerkannte Prüfstellen
        für die Elektrorevision
      </p>
      <p>
        <a href="https://www.vds.de" target="_blank">www.vds.de</a>
      </p>
    </div>

    <div class="link-wrapper">
      <p class="p-bold">Deutsche Rentenversicherung</p>
      <p>Gesetzlicher Rentenversicherungsträger</p>
      <p>
        <a href="https://www.deutsche-rentenversicherung.de" target="_blank"
          >www.deutsche-rentenversicherung.de</a
        >
      </p>
    </div>

    <div class="link-wrapper">
      <p class="p-bold">Anwaltsuchservice</p>
      <p>Onlinesuche eines Rechtsanwalts</p>
      <p>
        <a href="https://www.anwaltssuchservice.de" target="_blank"
          >www.anwaltssuchservice.de</a
        >
      </p>
    </div>

    <div class="link-wrapper">
      <p class="p-bold">Netzdoktor</p>
      <p>Medizinische Erläuterung für Fachbegriffe, Krankheiten</p>
      <p>
        <a href="https://www.netdoktor.de" target="_blank">www.netdoktor.de</a>
      </p>
    </div>

    <!--<div class="link-wrapper">
      <p class="p-bold">Wetter</p>
      <p>Unwetterbeobachtung online</p>
      <p>
        <a href="https://www.wetterlinks.de" target="_blank"
          >www.wetterlinks.de</a
        ><br /><br />
        <a href="https://www.wetteronline.de" target="_blank"
          >www.wetteronline.de</a
        ><br /><br />
        <a href="https://www.unwetterzentrale.de" target="_blank"
          >www.unwetterzentrale.de</a
        >
      </p>
    </div>-->
  </div>
</div>
