<div class="footer-wrapper">
  <div class="footer-wrapper-item" style="text-align: left">
    <div class="p-bold">Böttinger Versicherungsmakler GmbH</div>
    <div>Felix-Dahn-Str. 24, 70597 Stuttgart</div>
    <div>TEL: +49 (0) 711 / 974611-0</div>
    <div>Email: info@boettinger-gmbh.de</div>
  </div>

  <div class="footer-wrapper-item">
    <div [routerLink]="['/impressum']" class="customA">
      {{ "MENU_IMPRESSUM" | translate }}
    </div>
    <div [routerLink]="['/impressum']" fragment="datenschutz" class="customA">
      {{ "DATENSCHUTZ" | translate }}
    </div>

    <a href="https://boettinger.media" target="_blank"
      ><div class="made-by-wrapper">
        <div class="made-by-text">Made by</div>

        <img src="../../../assets/bm_logo.svg" class="made-by-img" /></div
    ></a>
  </div>
</div>
