<div class="opacity-wrapper"></div>
<div class="title-wrapper">
  <div class="title">{{ "NOTFOUND" | translate }}</div>
</div>

<div class="content-wrapper" style="margin-bottom: 100px">
  <div class="content-title">{{ "NOTFOUND_TEXT" | translate }}</div>
  <div class="content-text customA" [routerLink]="['/home']">
    {{ "NOTFOUND_TEXT2" | translate }}
  </div>
</div>
