<div class="opacity-wrapper"></div>
<div class="title-wrapper">
  <div class="title">{{ "MENU_KONTAKT" | translate }}</div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "COURTAGEHINWEIS_TITLE" | translate }}</div>
  <div class="content-text">{{ "COURTAGEHINWEIS" | translate }}</div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "KONTAKT_ANSPRECH" | translate }}</div>
  <div class="content-text">
    <p>{{ "SO_ERREICHEN_SIE_UNS" | translate }}</p>
  </div>
</div>

<div class="personen-wrapper">
  <div class="personen-item">
    <img class="personen-item-img" src="../../../assets/pers-kringler.jpg" />

    <div class="personen-item-content">
      <div class="personen-item-title">Martin Kringler</div>
      <div class="personen-item-text">{{ "CONTACT_FUHRER" | translate }}</div>
      <div class="personen-item-text">
        <a href="tel:004971197461111">Tel: +49 711 974611-11</a>
      </div>
      <div class="personen-item-text">
        <a href="tel:00491727302603">Mobil: +49 172 7302603</a>
      </div>
      <div class="personen-item-text">
        <a href="mailto:kringler@boettinger-gmbh.de"
          >kringler@boettinger-gmbh.de</a
        >
      </div>
    </div>
  </div>

  <div class="personen-item">
    <img class="personen-item-img" src="../../../assets/pers-wild.jpg" />

    <div class="personen-item-content">
      <div class="personen-item-title">Carmen Wildhaber</div>
      <div class="personen-item-text">{{ "CONTACT_HANDLUNG" | translate }}</div>
      <div class="personen-item-text">
        <a href="tel:004971197461122">Tel: +49 711 974611-22</a>
      </div>
      <div class="personen-item-text">
        <a href="mailto:wildhaber@boettinger-gmbh.de"
          >wildhaber@boettinger-gmbh.de</a
        >
      </div>
    </div>
  </div>

  <div class="personen-item">
    <img class="personen-item-img" src="../../../assets/pers-alleg.jpg" />

    <div class="personen-item-content">
      <div class="personen-item-title">Lorena Allegretta</div>
      <div class="personen-item-text">
        {{ "CONTACT_FACHWIRT" | translate }}
      </div>
      <div class="personen-item-text">
        <a href="tel:004971197461133">Tel: +49 711 974611-33</a>
      </div>
      <div class="personen-item-text">
        <a href="mailto:allegretta@boettinger-gmbh.de"
          >allegretta@boettinger-gmbh.de</a
        >
      </div>
    </div>
  </div>

  <div class="personen-item">
    <img class="personen-item-img" src="../../../assets/pers-mekler.jpg" />

    <div class="personen-item-content">
      <div class="personen-item-title">Cornelia Mekler</div>
      <div class="personen-item-text">
        {{ "CONTACT_INNENDIENST" | translate }}
      </div>
      <div class="personen-item-text">
        <a href="tel:004971197461144">Tel: +49 711 974611-44</a>
      </div>
      <div class="personen-item-text">
        <a href="mailto:mekler@boettinger-gmbh.de">mekler@boettinger-gmbh.de</a>
      </div>
    </div>
  </div>

  <div class="personen-item">
    <img class="personen-item-img" src="../../../assets/pers-salp.jpg" />

    <div class="personen-item-content">
      <div class="personen-item-title">Angelika Salpietro</div>
      <div class="personen-item-text">
        {{ "CONTACT_INNENDIENST" | translate }}
      </div>
      <div class="personen-item-text">
        <a href="tel:004971197461155">Tel: +49 711 974611-55</a>
      </div>
      <div class="personen-item-text">
        <a href="mailto:salpietro@boettinger-gmbh.de"
          >salpietro@boettinger-gmbh.de</a
        >
      </div>
    </div>
  </div>

  <div class="personen-item">
    <img class="personen-item-img" src="../../../assets/pers-kuttnerer.jpg" />

    <div class="personen-item-content">
      <div class="personen-item-title">Wolfgang Kütterer</div>
      <div class="personen-item-text">
        {{ "CONTACT_INNENDIENST" | translate }} /
        {{ "CONTACT_BUCHHALTUNG" | translate }}
      </div>
      <div class="personen-item-text">
        <a href="tel:004971197461144">Tel: +49 711 974611-44</a>
      </div>
      <div class="personen-item-text">
        <a href="mailto:kuetterer@boettinger-gmbh.de"
          >kuetterer@boettinger-gmbh.de</a
        >
      </div>
    </div>
  </div>
</div>

<div class="content-wrapper">
  <div class="content-title">{{ "KONTAKT_ANFAHRT" | translate }}</div>
  <div class="content-text">
    <p>
      B&ouml;ttinger Versicherungsmakler GmbH<br />
      Felix-Dahn-Str. 24<br />
      70597 Stuttgart
    </p>

    <p>
      {{ "ANFAHRT" | translate }}
    </p>
  </div>
</div>
<!--<div class="content-wrapper">
  <div class="content-subtitle">{{ "PARTNER_VERBAENDE" | translate }}</div>
  <div class="partner-wrapper">
    <img class="partner-item-img" src="../../../assets/logo-vvs.png" />
    <img class="partner-item-img" src="../../../assets/logo-falk.png" />
  </div>
</div>-->

<div class="content-wrapper">
  <div class="content-title">{{ "KONTAKT_KARTE" | translate }}</div>

  <div class="google-map">
    <iframe
      width="520"
      height="400"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      id="gmap_canvas"
      src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Felix-Dahn-Str.%2024%20Stuttgart+(B%C3%B6ttinger%20Versicherungsmakler%20GmbH)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    ></iframe>
  </div>
</div>
