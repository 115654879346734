<div class="opacity-wrapper"></div>
<div class="title-wrapper">
  <div class="title">{{ "IMPRESSUM" | translate }}</div>
</div>

<div class="content-wrapper" style="margin-bottom: 100px">
  <div class="content-title">Böttinger Versicherungsmakler GmbH</div>
  <div class="content-text">
    <p>
      {{ "ANSCHRIFT" | translate }}: Felix-Dahn-Str. 24, D 70597 Stuttgart
      Degerloch<br />
      {{ "TELEFON" | translate }}: &nbsp;+49 (0) 711 / 974611 - 0<br />
      {{ "TELEFAX" | translate }}: &nbsp;+49 (0) 711 / 974611 - 66
    </p>

    <p class="p-bold">{{ "IMP_TITLE_1" | translate }}</p>
    <p>{{ "IMP_TEXT_1" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_2" | translate }}</p>
    <p>{{ "IMP_TEXT_2" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_3" | translate }}</p>
    <p>{{ "IMP_TEXT_3" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_4" | translate }}</p>
    <p>{{ "IMP_TEXT_4" | translate }}</p>
    <p>{{ "IMP_TEXT_5" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_401" | translate }}</p>
    <p>{{ "IMP_TEXT_501" | translate }}</p>

    <p>{{ "IMP_TEXT_6" | translate }}</p>
    <p>{{ "IMP_TEXT_7" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_8" | translate }}</p>
    <p>{{ "IMP_TEXT_8" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_9" | translate }}</p>
    <p>{{ "IMP_TEXT_9" | translate }}</p>
    <p>{{ "IMP_TEXT_10" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_27" | translate }}</p>
    <p>{{ "IMP_TEXT_27" | translate }}</p>
    <p>{{ "IMP_TEXT_28" | translate }}</p>
    <p>{{ "IMP_TEXT_29" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_30" | translate }}</p>
    <p>{{ "IMP_TEXT_30" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_11" | translate }}</p>
    <p>{{ "IMP_TEXT_11" | translate }}</p>

    <p>{{ "IMP_TITLE_12" | translate }}</p>
    <p>{{ "IMP_TEXT_12" | translate }}</p>
    <p>{{ "IMP_TEXT_13" | translate }}</p>
    <p>{{ "IMP_TEXT_14" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_15" | translate }}</p>
    <p>{{ "IMP_TEXT_15" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_16" | translate }}</p>
    <p>{{ "IMP_TEXT_16" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_17" | translate }}</p>
    <p>{{ "IMP_TEXT_17" | translate }}</p>

    <p class="p-bold" id="datenschutz" name="datenschutz">
      {{ "IMP_TITLE_18" | translate }}
    </p>
    <p>{{ "IMP_TEXT_18" | translate }}</p>

    <p class="p-bold">
      {{ "IMP_TITLE_19" | translate }}
    </p>

    <p class="p-bold">{{ "IMP_TITLE_20" | translate }}</p>
    <p>{{ "IMP_TEXT_20" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_21" | translate }}</p>
    <p>{{ "IMP_TEXT_21" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_22" | translate }}</p>
    <p>{{ "IMP_TEXT_22" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_23" | translate }}</p>
    <p>{{ "IMP_TEXT_23" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_24" | translate }}</p>
    <p>{{ "IMP_TEXT_24" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_25" | translate }}</p>
    <p>{{ "IMP_TEXT_25" | translate }}</p>

    <p class="p-bold">{{ "IMP_TITLE_26" | translate }}</p>
    <p>{{ "IMP_TEXT_26" | translate }}</p>
  </div>
</div>
